@import 'import';
$animation-time: var(--marquee-animation-time, 20s);
$animation-delay: var(--marquee-animation-delay, 0.5s);

$gap-contents: var(--marquee-gap-contents, 20px);

$card-bg: $blue-light;
$card-color: $black;

$clear-button-bg: #c6ecf3;
$clear-button-icon-border-color: $gray-510;
$clear-button-icon-bg: $white;

.root {
  user-select: none;

  position: relative;
  z-index: 1;

  overflow: hidden;
  display: flex;

  height: 48px;

  font-size: rem(15);
  line-height: rem(20);
  color: $card-color;
  text-decoration: none;
  letter-spacing: -0.4px;

  background-color: $card-bg;

  @include media-breakpoint-down(sm) {
    font-size: rem(13);
    letter-spacing: -0.2px;
  }
}

.wrapper {
  cursor: default;

  overflow: hidden;
  display: flex;

  width: 100%;
  min-width: 0;
  height: 100%;

  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &._clickable {
    cursor: pointer;
  }
}

.content {
  will-change: transform;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  min-width: 100%;
  height: 100%;
  padding-left: $gap-contents;

  animation: marquee $animation-time $animation-delay linear infinite both;

  .wrapper:hover & {
    animation-play-state: paused;
  }
}

.label {
  margin: 0;

  font-weight: 400;
  line-height: rem(24);
  text-decoration: underline;
  text-transform: capitalize;
  letter-spacing: 0;

  &._mobile {
    display: none;

    @include media-breakpoint-down(sm) {
      display: inline;
    }
  }

  &._desktop {
    display: inline;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.clearButton {
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0 20px 0 25px;

  color: inherit;

  background-color: $clear-button-bg;
  box-shadow: 0 0 20px 0 rgba($black-deep, 0.2);

  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }

  :global(.Icon) {
    display: block;

    width: 20px;
    height: 20px;
    margin-left: 4px;
    padding: 4px;

    background-color: $clear-button-icon-bg;
    border: 0.63px solid $clear-button-icon-border-color;

    svg {
      display: block;
      fill: currentcolor;
    }
  }

  &:hover {
    color: inherit;

    .label {
      color: inherit;
    }

    :global(.Icon) {
      background-color: $clear-button-icon-bg;
      border-color: $clear-button-icon-border-color;
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(190%);
  }

  to {
    transform: translateX(45%);
  }
}
